<template>
  <v-form v-model="formValid" ref="creditNoteForm">
    <StandardDialog
      v-model="supplierDataErrorDialog"
      :dialog-height="null"
      dialog-width="400px"
      title="Dati Azienda Mancanti"
      @close="supplierDataErrorDialog = false"
    >
    <v-alert 
      border="left"
      class="py-2 my-3"
      color="error"
    >
     {{supplierDataErrorMessage}}
    </v-alert> 
    </StandardDialog>
    <v-subheader class="font-italic font-weight-light py-1">
      Generale
    </v-subheader>
    <v-row>
      <v-col
        cols="6"
        sm="6"
        md="2"
        lg="2"
        xl="2"
      >
        Codice Interno Fattura
        <v-text-field
          v-model="internalCode"
          flat
          filled
          rounded
          disabled
          prepend-icon="mdi-code-tags"
          hide-details="auto"
          @input="updateObject('internalCode', $event)"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        Numero Fattura o Documento
        <v-text-field
          :disabled="disabledForm"
          v-model="documentNumber"
          flat
          rounded
          filled
          label="Numero documento"
          hide-details="auto"
          @input="updateObject('documentNumber', $event)"
          :rules="[presenceRule]"
        ></v-text-field>
      </v-col>
      <v-col 
        cols="12"
        sm="12"
        md="7"
        lg="7"
        xl="7"
        v-if="status !== 'paid' && ['new'].includes(variant)"
      >
        <SuppliersAutocomplete
          class="ma-5 pr-5"
          v-model="supplierSelected"
          :clearable="type === 'warehouse'"
          return-object
          :error="!supplierSelected"
          @input="handleSupplierChange"
          prepend-icon="mdi-domain"
          :append-outer-icon="!!supplierSelected ? 'mdi-pencil' : undefined"
          :auto-detect-new-supplier="false"
        ></SuppliersAutocomplete>
      </v-col>
      <v-col 
        cols="12"
        sm="12"
        md="7"
        lg="7"
        xl="7"
        v-if="['edit', 'detail'].includes(variant)"
      >
        <!-- <v-text-field
          class="border-default ma-5"
          v-model="customerNameToShow"
          v-if="invoiceHeadedToSelected === 'customer'"
          readonly
          flat
          solo
          filled
          label="Cliente"
          hide-details="auto"
        ></v-text-field> -->
          <v-text-field
          class="ma-5"
          v-model="supplierNameToShow"
          v-if="invoiceHeadedToSelected === 'supplier'"
          readonly
          flat
          rounded
          filled
          label="Azienda"
          hide-details="auto"
        ></v-text-field> 
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col
            cols="6"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            Note / Descrizione Movimento
            <v-textarea
              :disabled="disabledForm"
              v-model="description"
              flat
              rounded
              filled
              hide-details="auto"
              @input="updateObject('description', $event)"
              :rules="[presenceRule]"
              rows="2"
            ></v-textarea>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            Fattura originale
            <v-text-field
              :value="getLinkedInvoiceDescription"
              filled
              :disabled="disabledForm"
              flat
              rounded
              hide-details="auto"
              readonly
              @click="openLinkedInvoice"
            ></v-text-field>
          </v-col>  
          <v-col 
            cols="6"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            {{ "Data Emissione Nota" }}
            <div>
              <DatePickerCommon
                filled
                flat
                :styles="{
                  'pointer-events': ['new'].includes(variant) ? 'all' : 'none',
                  opacity: ['new'].includes(variant) ? '1' : '0.6',
                }"
                label="Data Nota"
                v-model="emittedAt"
                @input="updateObject('emittedAt', $event)"
                :rules="[presenceRule]"
                :error="!emittedAt"
                :readonly=" (variant=='detail') && !editOptionByAdmin ? true : false"
              ></DatePickerCommon>
            </div>
          </v-col>
          <v-col 
            cols="6"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            {{ "Data Scadenza" }}
            <div>
              <DatePickerCommon
                filled
                flat
                :styles="{
                  'pointer-events': ['new'].includes(variant) ? 'all' : 'none',
                  opacity: ['new'].includes(variant) ? '1' : '0.6',
                }"
                label="Data Scadenza"
                v-model="expirationDate"
                :rules="[presenceRule]"
                :error="!expirationDate"
                @input="updateObject('expirationDate', $event)"
                :readonly=" ((variant=='detail') && !editOptionByAdmin) ? true : false"
              ></DatePickerCommon>
            </div>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col
            cols="6"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            Modalità Di Pagamento
            <v-select
              v-model="paymentTermId"
              :items="paymentTermsItems"
              item-value="id"
              item-text="description"
              label="Modalità Di Pagamento"
              filled
              :disabled="disabledForm"
              flat
              dense
              rounded
              hide-details="auto"
              :rules="[presenceRule]"
              :readonly=" variant=='new' ? false : true"
              prepend-icon="mdi-contactless-payment"
              @change="updateObject('paymentTermId', $event)"
            ></v-select>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            Tipo Natura Esenzione IVA
            <v-select
              v-model="paymentTypeNatureExemptionId"
              :items="paymentTypeNatureExemptionItems"
              item-value="id"
              item-text="description"
              :disabled="disabledForm"
              label="Tipo Natura Esenzione IVA"
              filled
              rounded
              flat
              dense
              hide-details="auto"
              :readonly=" variant=='new' ? false : true"
              prepend-icon="mdi-contactless-payment"
              @change="updateObject('paymentTypeNatureExemptionId', $event)"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            Operatore
            <OperatorsAutocomplete
              v-model="operatorId"
              :multiple="false"
              @input="updateObject('operatorId', $event)"
              :error="!operatorId"
              flat
              dense
            ></OperatorsAutocomplete>  
          </v-col> 
        </v-row>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col 
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Aliquota
        <TaxesListSelector
          v-model="additionalVatPercentage[0]"
          :rules="[greaterThan0]"
          :readonly=" ((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false"
          @input="() => {
            updateAdditionalTaxable(0)
            updateTotalAdditionalAmount()
          }"
        ></TaxesListSelector>
      </v-col>
      <v-col 
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Imponibile
        <v-text-field
          v-model="additionalTaxable[0]"
          dense
          filled
          label="Imponibile"
          hide-details="auto"
          :rules="[greaterThan0]"
          rounded
          flat
          type="number"
          :readonly=" ((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false"
          @input="() => {
            updateAdditionalTaxable(0)
            updateTotalAdditionalAmount()
          }"
          :error="overTotalAmount"
        ></v-text-field>
      </v-col>
      <v-col 
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Importo
        <v-text-field
          v-model="additionalAmount[0]"
          dense
          filled
          label="Importo"
          type="number"
          hide-details="auto"
          rounded
          flat
          readonly
        ></v-text-field>
        <!--
          :input="updateAdditionalAmount(0)"
          -->
      </v-col>
      <v-col 
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Importo Iva
        <v-text-field
          v-model="additionalVatAmount[0]"
          dense
          filled
          label="Importo Iva"
          readonly
          rounded
          hide-details="auto"
          flat
        ></v-text-field>
      </v-col>
      <v-col 
        cols="12"
        sm="4"
        md="4"
        lg="4"
        xl="4"
      >
        Tag
        <TagsAutocomplete
          v-model="additionalTag[0]"
          @input="updateObject('additionalTag', additionalTag)"
          dense
          return-object
          :rules="[presenceRule]"
          :readonly="((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false"
          :multiple="false"
          :filters="{typeCost:false}"
        ></TagsAutocomplete>  
      </v-col> 
    </v-row>
            <!-- Per più aliquote -->
            <v-row v-for="(n) in nTaxes" :key="n">
      <v-col 
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Aliquota
        <TaxesListSelector
          v-model="additionalVatPercentage[n]"
          :rules="[greaterThan0]"
          :readonly=" ((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false"
          @input="() => {
            updateAdditionalTaxable(n)
            updateTotalAdditionalAmount()
          }"
        ></TaxesListSelector>
      </v-col>
      <v-col 
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Imponibile
        <v-text-field
          v-model="additionalTaxable[n]"
          dense
          filled
          label="Imponibile"
          hide-details="auto"
          :rules="[greaterThan0]"
          rounded
          type="number"
          @input="() => {
            updateAdditionalTaxable(n)
            updateTotalAdditionalAmount()
          }"   
          :readonly="((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false"
          :error="overTotalAmount"
        ></v-text-field>
      </v-col>
      <v-col 
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Importo
        <v-text-field
          v-model="additionalAmount[n]"
          :rules="[greaterThan0]"
          dense
          filled
          label="Importo"
          type="number"
          hide-details="auto"
          rounded
          readonly
        ></v-text-field>
        <!--
          :input="updateAdditionalAmount(n)"
        -->
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Importo Iva
        <v-text-field
          v-model="additionalVatAmount[n]"
          dense
          filled
          label="Importo Iva"
          readonly
          hide-details="auto"
          rounded
        ></v-text-field>
      </v-col>
      <v-col 
        cols="12"
        sm="4"
        md="4"
        lg="4"
        xl="4"
      >
        Tag
        <TagsAutocomplete
          v-model="additionalTag[n]"
          @input="updateObject('additionalTag', additionalTag)"
          dense
          return-object
          :rules="[presenceRule]"
          :error="!additionalTag[n]"
          :readonly="((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false"
          :multiple="false"
          :filters="{typeCost:true}"
        ></TagsAutocomplete>  
      </v-col> 
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center mb-2">
        <v-btn color="primary" outlined class="px-10 mr-2" @click="addRow()" :disabled="((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn color="error" outlined class="px-10 ml-2" @click="removeRow()" :disabled="nTaxes < 1 || ((variant=='detail') && !editOptionByAdmin) || (!!invoice.status && invoice.status=='beingPaid')? true : false">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <StandardDialog
      title="Fattura Originale"
      v-model="linkedInvoiceDialog"
      dialog-height="90dvh"
      :dialog-width="!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? '90vw'
        : '60vw'"
      persistent
    >
      <div class="d-flex justify-center align-center mb-3" style="width: 100%;">
        <AdvancedFilter
          style="width:99%; height: 100%;"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Nome: {{ filter.value}}
          </template>

          <template v-slot:chip-custom-type="{filter}">
            Tipo: {{ filter.value.map(el => el.name).join(', ')}}
          </template>

          <template v-slot:custom-archived="{filter}">
            <v-row class="pa-2 px-6">
              <v-col>
                <v-switch
                  v-model="filter.value"
                  :label="filter.label"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-archived="{filter}">
              {{ filter.value ? 'Archiviate' : 'Non Archiviate' }}
          </template>

          <template v-slot:custom-status="{filter}">
            <v-row class="pa-6">
              <v-col>
                <v-select
                  v-model="filter.value"
                  :items="[
                    { type: 'cashed', name: 'Incassata' },
                    { type: 'pending', name: 'Fattura da incassare' },
                  ]"
                  :label="filter.label"
                  :multiple="true"
                  item-text="name"
                  item-value="type"
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </template>

          <template v-slot:custom-type="{filter}">
            <v-row class="pa-6">
              <v-col>
                <v-select
                  v-model="filter.value"
                  :items="[
                    { type: 'warehouse', name: 'Automatica magazzino' },
                    { type: 'creditNote', name: 'Nota a Credito da Incassare' },
                    { type: 'creditNoteToPay', name: 'Nota a Credito da Pagare' },
                    { type: 'other', name: 'Altro' },
                    { type: 'cashDesk', name: 'Automatica in cassa' },
                    { type: 'otherToCash', name: 'Manuale' }
                  ]"
                  :label="filter.label"
                  return-object
                  :multiple="true"
                  item-text="name"
                  item-value="type"
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </template>

          <template v-slot:custom-operator="{filter}">
            <v-row class="pb-5 pl-5">
              <v-col>
                <OperatorsAutocomplete
                  v-model="filter.value"
                  :multiple="false"
                  :filled="false"
                  dense
                  label="Operatore"
                >
                </OperatorsAutocomplete>
              </v-col>
            </v-row>
          </template>
          
          <template v-slot:custom-emittedTo="{filter}">
            <v-row class="pa-4 pl-6">
              <v-col>
                <v-text-field
                  v-model="filter.value"
                  :label="filter.label"
                  :filled="false"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </AdvancedFilter>
      </div>
      <div style="height: 83%;" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          :show-select="false"
          loading-text="Caricamento invoices ..."
          :headers="headers"
          :loading="loading"
          :items="invoices"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :deletable="false"
          :show-actions="false"
        >
          <template v-slot:custom-emittedTo="{ item }">
            {{ item.lastname }}, {{ item.address }} (P.IVA {{ item.vatNumber }})
          </template>
          <template v-slot:custom-customer="{ item }">
            {{ item.firstname }} {{ item.lastname }}
          </template>
          <template v-slot:custom-amount="{ item }">
            <v-chip :color="!!item.ghost ? 'error' : 'primary'">
              {{ item["amount"] ? Number(item["amount"]).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) : (0).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }}</v-chip
            >
          </template>
        </TypeDataTable>
      </div>
      <template v-slot:footer-actions>
        <v-btn text color="error" v-on:click="linkedInvoiceDialog = false">
          Chiudi
        </v-btn>
        <v-btn text color="primary" v-on:click="handleSelectLinkedInvoice" :disabled="!selected">
          Seleziona
        </v-btn>
      </template>
    </StandardDialog>
  </v-form>
</template>

<script>
import Vue from "vue";
import invoiceForm from "@/services/invoices/invoice.form.js";

import TagsAutocomplete from "@/components/common/TagsAutocomplete.vue";
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import TaxesListSelector from "@/components/areas/payments/taxes/TaxesListSelector.vue"

import invoiceService from "@/services/invoices/invoices.service.js";
import dataInvoiceService from "@/services/invoices/dataInvoices.service.js";
import paymentTermService from "@/services/paymentTerms/paymentTerms.service.js";
import paymentConditionService from "@/services/paymentTerms/paymentConditions.service.js";
import paymentTypeNatureExemptionService from "@/services/paymentTerms/paymentTypeNatureExemptions.service.js";
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
import StandardDialog from "@/components/common/StandardDialog.vue"
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";



export default {
  name: "GeneralFormTab",
  components: {
    SuppliersAutocomplete,
    DatePickerCommon,
    TaxesListSelector,
    TagsAutocomplete,
    OperatorsAutocomplete,
    StandardDialog,
    AdvancedFilter,
    TypeDataTable
  },
  data: function () {
    return {
      amount: undefined,
      vatAmount: undefined,
      taxable: undefined,
      type: undefined,
      oldType: undefined,
      emittedAt: undefined,
      expirationDate: undefined,
      status: "",
      lastname: undefined,
      description: undefined,
      firstname: undefined,
      vatPercentage: undefined,
      id: undefined,
      code: 0,
      internalCode: "",
      documentNumber: undefined,
      operatorId: undefined,
      supplierId: undefined,
      linkedInvoiceId: undefined,
      linkedInvoice: undefined,
      supplierDataErrorDialog: false,
      supplierDataErrorMessage: "Assicurarsi che l'Azienda selezionata abbia i campi: Partita IVA, Indirizzo, Codice Destinatario, Mail e Nome Salvati!",

      linkedInvoiceDialog: false,
      filterTypes: [
        { type: 'date', operator: 'between', field: 'emittedAt', name: 'Emessa il', label: 'Il giorno', color: "", value: [undefined, undefined] },
        { type: 'date', operator: 'between', field: 'expirationDate', name: 'Data di scadenza', label: 'Il giorno', color: "", value: [undefined, undefined] },
        { type: 'string', operator: 'like', field: 'documentNumber', name: 'Numero Fattura', label: 'Numero Fattura', color: "", value: undefined },
        { type: 'number', operator: 'equal', field: 'amount', name: 'Ammontare', label: 'Ammontare', color: "", value: undefined },
        { type: 'string', operator: 'equal', field: 'vatNumber', name: 'P.IVA', label: 'P.IVA', color: "", value: undefined },
        { type: 'string', operator: 'equal', field: 'internalCode', name: 'Codice Interno', label: 'Codice Interno', color: "", value: undefined },
        { type: 'custom', operator: 'custom', field: 'status', name: 'Stato', label: 'Stato', color: "", value: [
          { type: 'paid', name: 'Pagata' },
          { type: 'cashed', name: 'Incassata' },
          { type: 'pending', name: 'Fattura da incassare' },
          { type: 'beingPaid', name: "Pagamento a rate"},
        ], icon: 'mdi-format-list-bulleted-type' }, 
        { type: 'custom', operator: 'custom', field: 'emittedTo', name: 'Intestata a', label: 'Intestata a', color: "", value: undefined, icon: 'mdi-text-search' },
      ],
      advanceFilters: [
        { type: 'custom', operator: 'custom', field: 'type', name: 'Tipo', label: 'Tipo', color: "", value: [
          { type: 'warehouse', name: 'Automatica magazzino' },
          { type: 'creditNote', name: "Nota a Credito" },
          { type: 'creditNoteToPay', name: 'Nota a Credito da Pagare' },
          { type: 'other', name: "Altro" },
          { type: 'cashDesk', name: "Automatica in cassa" },
          { type: 'otherToCash', name: "Manuale" }
        ], icon: 'mdi-format-list-bulleted-type' },
        //{ type: 'custom', operator: 'custom', field: 'operator', name: 'Operatore', label: 'Label', color: "", icon: 'mdi-account'},
        //{ type: 'custom', operator: 'custom', field: 'paymentCredits', name: 'Con Rate', label: 'Con Rate', color: "", value: undefined, icon: 'mdi-credit-card-clock-outline' },
        { type: 'string', operator: 'like', field: 'description', name: 'Descrizione', label: 'Descrizione', color: "", value: undefined },

        { type: 'custom', operator:'custom', field: 'archived', name: 'Archiviate', label: 'Archiviate', color: "", value: false, icon: 'mdi-archive' }
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Nome', color: "", value: undefined, icon: 'mdi-text-search' },
      filtersValue: [
      ],
      page: 1,
      rowPerPage: 100,
      totalPages: 0,
      invoices: [],
      headers: [
        { text: "Privato/Azienda", value: "customer", type: "custom" },
        { text: "Codice interno", value: "internalCode" },
        { text: "Numero Fattura o Documento", value: "documentNumber" },
        { text: "Ammontare", value: "amount", type: "custom" },
        { text: "Emessa il", value: "emittedAt", type: "datetime" },
      ],
      loading: false,
      selected: undefined,

      invoiceTypes: invoiceService.listType(),
      loadingInvoice: false,
      formValid: false,
      typeCashDesk: "cashDesk",
      supplierSelected: null,
      customerSelected: null,
      suppliers: [],
      customers: [],
      orderSelected: [],
      billSelected: [],
      invoiceHeadedToSelected: "",
      invoiceTypeSelected: "",
      disabledForm: false,
      invoicePaid: false,
      invoicePaidMessage: "",
      paymentTypeNatureExemptionId: undefined,
      paymentTypeNatureExemptionItems: [],
      paymentTermId: undefined,
      paymentTermsItems: [],
      tags: [],
      supplierNameToShow: undefined,
      nTaxes: 0,
      additionalVatPercentage: [undefined],
      additionalVatAmount: [undefined],
      additionalTaxable: [undefined],
      additionalAmount: [undefined],
      additionalTag: [undefined],
      totAdditionalAmount: 0,
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
      greaterThan0: (v) => {
        return (!isNaN(v) && v >= 0 ) || "Importo non valido";
      }
    };
  },
  props: {
    invoice: {
      type: Object,
      default: () => {
        return {};
      }
    },
    bus: {
      type: Object,
      default: () => {
        return new Vue();
      }
    },
    variant: {
      type: String,
      default: "new",
      validator: function (value) {
        if (!["new", "edit", "detail"].includes(value)) {
          return "invalid variant";
        } else {
          return true;
        }
      },
    },
    editOptionByAdmin: {
        type: Boolean,
        default: false
      },
  },
  mounted: function () {
    var self = this;
    invoiceForm.on("update", function (data) {
      self.handleObjectChanges(data.invoice);
    });
    this.lastInternalCode();
    this.handleInvoiceHeadedToSelected("supplier");
    this.fetchPaymentTerms();

    this.handleInvoiceTypeSelected();
    this.updateObject('type','creditNoteToPay')
    if (this.variant === "detail") {
      this.disabledForm = true;
    }

    this.$refs.creditNoteForm.validate()
  },
  methods: {
    fetchPaymentTerms() {
      new Promise(async (resolve, reject) => {
        let resultPaymentTermsItems = await paymentTermService.list();
        this.paymentTermsItems = [...resultPaymentTermsItems.rows];

        let resultPaymentConditionItems = await paymentConditionService.list();
        this.paymentConditionItems = [...resultPaymentConditionItems.rows];

        let resultPaymentTypeNatureExemptionItems =
          await paymentTypeNatureExemptionService.list();
        this.paymentTypeNatureExemptionItems = [
          ...resultPaymentTypeNatureExemptionItems.rows,
        ];

        this.docPrintItems = dataInvoiceService.listDocPrint();
        resolve();
      });
    },
    lastInternalCode() {
      invoiceService.getLastInvoice().then((lastInvoice) => {
        let progressiveInCode;
        let today = new Date();
        if (
          !!lastInvoice &&
          Number(lastInvoice.createdAt.substring(0, 4)) == today.getFullYear()
        ) {
          progressiveInCode = (Number(lastInvoice.progressive) + 1).toString();
          while (progressiveInCode.length < 5) {
            progressiveInCode = "0" + progressiveInCode;
          }
        } else {
          progressiveInCode = "00001";
        }
        this.internalCode =
          "FAT" + today.getFullYear() + "-" + progressiveInCode;
      });
    },
    lastCode(type) {
      invoiceService.getLastCode(type).then((lastCode) => {
        if (lastCode)
          this.code =
            !!this.oldType && this.oldType !== type
              ? Number(lastCode) + 1
              : Number(lastCode);
        else this.code = 1;
        this.updateObject("code", this.code);
      });
    },
    handleInvoiceHeadedToSelected(value) {
      this.invoiceHeadedToSelected = value;
    },
    handleInvoiceTypeSelected(value) {
      this.lastCode(value);
      this.invoiceTypeSelected = value;
      this.updateObject("type", value);
    },
    handleSupplierChange(supplierSelected) {
      if(!!supplierSelected){
        if(!supplierSelected.id || !supplierSelected.VATNumber || !supplierSelected.address || !supplierSelected.businessName || !supplierSelected.email || !supplierSelected.dataInvoice.destinataryCode){
          this.supplierSelected = undefined
          this.supplierDataErrorDialog = true
        }
        else{
          invoiceForm.updateFields(['supplierId', 'vatNumber', 'address', 'lastname', 'mail', 'destinataryCode'], 
          [supplierSelected.id, supplierSelected.VATNumber, supplierSelected.address, supplierSelected.businessName, supplierSelected.email, supplierSelected.dataInvoice.destinataryCode])
          this.bus.$emit("change-suppplier", supplierSelected)
        }
      }
    },
    openLinkedInvoice() {
      this.fetchInvoices();
      this.linkedInvoiceDialog = true;
    },
    fetchInvoices() {
      this.loadingItems = true;
      let filters = [...this.filtersValue, 
        { type: 'custom', operator: 'custom', field: 'ghost', value: this.canViewGhost },
        { type: 'boolean', operator: 'equal', field:'activeCycle', value: true }
      ];

      invoiceService
        .list(this.page, this.rowPerPage, filters)
        .then((results) => {
          if (this.rowPerPage != results.rowPerPage) {
            this.rowPerPage = results.rowPerPage;
          }

          if (this.page != results.page) {
            this.page = results.page;
          }

          this.totalPages = results.totalPages;
          if (this.totalPages < this.page && this.totalPages != 0) {
            this.page = this.totalPages;
          }

          this.totalRow = [results.totalRow]
          this.invoices = results.rows;
          this.loadingItems = false;
        });
    },
    applyFilters(filters) {
      this.fetchInvoices();
    },
    handleSelectLinkedInvoice() {
      this.linkedInvoiceDialog = false;
      this.linkedInvoice = this.selected[0];
      this.updateObject("linkedInvoiceId", this.linkedInvoice.id);

      if (this.linkedInvoice.invoiceSupplier) {
        this.supplierSelected = this.linkedInvoice.invoiceSupplier.supplier
      }
      
      const invoiceServices = this.linkedInvoice.invoiceServices.map(el => {
        return {
          ...el,
          tags: el.service ? el.service.tags: undefined
        }
      })
      const invoiceItems = this.linkedInvoice.invoiceItems.map(el => {
        return {
          ...el,
          tags: el.item ? el.item.tags : undefined
        }
      })
      const invoiceGenericElements = this.linkedInvoice.invoiceGenericElements

      const invoiceAllElements = (invoiceServices || []).concat(invoiceItems || [])
        .concat(invoiceGenericElements || [])

      for (let i = 0; i < invoiceAllElements.length; i++) {
        const priceQuantity = Number((Number(invoiceAllElements[i].price) * Number(invoiceAllElements[i].quantity)).toFixed(2))
        const amount = Number((priceQuantity - Number((priceQuantity * (Number(invoiceAllElements[i].discount || 0) / 100)).toFixed(2))).toFixed(2))
        const taxable = Number((amount / (1 + (Number(invoiceAllElements[i].vatPercentage) / 100))).toFixed(2))
        const vatAmount = Number((amount - taxable).toFixed(2))

        if (this.additionalVatPercentage[0] == undefined && this.additionalVatAmount[0] == undefined &&
          this.additionalTaxable[0] == undefined && this.additionalAmount[0] == undefined
        ) {
          
          this.additionalVatPercentage[0] = Number(invoiceAllElements[i].vatPercentage)
          this.additionalVatAmount[0] = vatAmount
          this.additionalTaxable[0] = taxable
          this.additionalAmount[0] = amount
          this.additionalTag[0] = invoiceAllElements[i].tags
          this.additionalTag[i][0].description = this.additionalTag[i][0].tagCategoryName + " - " + this.additionalTag[i][0].description

          continue
        } 

        this.nTaxes++;

        this.additionalVatPercentage.push(Number(invoiceAllElements[i].vatPercentage))
        this.additionalVatAmount.push(vatAmount)
        this.additionalTaxable.push(taxable)
        this.additionalAmount.push(amount)
        this.additionalTag.push(invoiceAllElements[i].tags)
        this.additionalTag[i][0].description = this.additionalTag[i][0].tagCategoryName + " - " + this.additionalTag[i][0].description
      }

      if (!!this.supplierSelected) {
        invoiceForm.updateFields(['additionalVatPercentage','additionalTaxable',
          'additionalVatAmount','additionalAmount', 'additionalTag', 'supplierId', 'vatNumber', 
          'address', 'lastname', 'mail', 'destinataryCode'], 
          [this.additionalVatPercentage, this.additionalTaxable, this.additionalVatAmount, 
            this.additionalAmount, this.additionalTag, this.supplierSelected.id, 
            this.supplierSelected.VATNumber, this.supplierSelected.address, 
            this.supplierSelected.businessName, this.supplierSelected.email, 
            this.supplierSelected.dataInvoice.destinataryCode])
      } else {
        invoiceForm.updateFields(['additionalVatPercentage','additionalTaxable',
          'additionalVatAmount','additionalAmount', 'additionalTag'], 
          [this.additionalVatPercentage, this.additionalTaxable, this.additionalVatAmount, 
            this.additionalAmount, this.additionalTag])
      }
    },
    fields() {
      return [
        "lastname",
        "description",
        "amount",
        "type",
        "firstname",
        "vatPercentage",
        "internalCode",
        "code",
        "status",
        "id",
        "operatorId",
        "taxable",
        "vatAmount",
        "documentNumber",
        "tags",
        "linkedDocuments",
        "linkedInvoiceId",
        "additionalVatPercentage",
        "additionalVatAmount",
        "additionalTaxable",
        "additionalAmount",
        "additionalTag",
      ];
    },
    handleObjectChanges(invoice) {
      const fields = this.fields();
      const newValKeys = Object.keys(invoice);

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];

        if (newValKeys.includes(field) && invoice[field] != this[field]) {
          this[field] = invoice[field];
        }
      }
      if (!!invoice.emittedAt && !this.emittedAt) {
        this.emittedAt = new Date(invoice.emittedAt);
      }
      if (!!invoice.expirationDate && !this.expirationDate) {
        this.expirationDate = new Date(invoice.expirationDate);
      }
      if (!!invoice.paymentTermId && !this.paymentTermId) {
        this.paymentTermId = Number(invoice.paymentTermId);
      }
      if (!!invoice.paymentTypeNatureExemptionId && !this.paymentTypeNatureExemptionId) {
        this.paymentTypeNatureExemptionId = Number(invoice.paymentTypeNatureExemptionId);
      }
      if(!!invoice.lastname && !this.supplierSelected){
        this.supplierNameToShow = invoice.lastname
      }
      if(!!invoice.additionalVatPercentage && !this.additionalVatPercentage){
        this.additionalVatPercentage = invoice.additionalVatPercentage
      }
      if(!!invoice.additionalVatAmount && !this.additionalVatAmount){
        this.additionalVatAmount = invoice.additionalVatAmount
      }
      if(!!invoice.additionalTaxable && !this.additionalTaxable){
        this.additionalTaxable = invoice.additionalTaxable
      }
      if(!!invoice.additionalAmount && !this.additionalAmount){
        this.additionalAmount = invoice.additionalAmount
      }
      if(!!invoice.linkedInvoiceId && !this.linkedInvoiceId){
        this.linkedInvoiceId = invoice.linkedInvoiceId
      }
      if(!!invoice.linkedInvoice && !this.linkedInvoice){
        this.linkedInvoice = invoice.linkedInvoice
      }
      if(!!invoice.invoiceAmounts && !!invoice.invoiceAmounts.length && this.additionalVatPercentage[0] === undefined){
        this.nTaxes = invoice.invoiceAmounts.length - 1
        this.additionalVatPercentage[0] = Number(invoice.invoiceAmounts[0].vatPercentage)
        this.additionalVatAmount[0] = Number(invoice.invoiceAmounts[0].vatAmount)
        this.additionalTaxable[0] = Number(invoice.invoiceAmounts[0].taxable)
        this.additionalAmount[0] = Number(invoice.invoiceAmounts[0].amount)
        this.additionalTag[0] = invoice.invoiceAmounts[0].tags
        this.additionalTag[0][0].description = this.additionalTag[0][0].tagCategoryName + " - " + this.additionalTag[0][0].description
        for(let i = 1; i < invoice.invoiceAmounts.length; i++){   
          this.additionalVatPercentage[i] = Number(invoice.invoiceAmounts[i].vatPercentage)
          this.additionalAmount[i] = Number(invoice.invoiceAmounts[i].amount)
          this.additionalVatAmount[i] = Number(invoice.invoiceAmounts[i].vatAmount)
          this.additionalTaxable[i] = Number(invoice.invoiceAmounts[i].taxable)
          this.additionalTag[i] = invoice.invoiceAmounts[i].tags
          this.additionalTag[i][0].description = this.additionalTag[i][0].tagCategoryName + " - " + this.additionalTag[i][0].description
        }
        //delete invoice.invoiceAmounts
      }
    },
    updateObject(key, value) {
      if (key == "type" && !this.oldType) {
        this.oldType = value;
      }
      invoiceForm.updateField(key, value);
    },
    addRow(){
      this.nTaxes++;

      this.additionalVatPercentage.push(undefined)
      this.additionalVatAmount.push(undefined)
      this.additionalTaxable.push(undefined)
      this.additionalAmount.push(undefined)
      this.additionalTag.push(undefined)
    },
    removeRow(){
      this.additionalVatPercentage.pop()
      this.additionalVatAmount.pop()
      this.additionalTaxable.pop()
      this.additionalAmount.pop()
      this.additionalTag.pop()

      invoiceForm.updateFields(['additionalVatPercentage','additionalTaxable','additionalVatAmount','additionalAmount', 'additionalTag'], [this.additionalVatPercentage, this.additionalTaxable, this.additionalVatAmount, this.additionalAmount, this.additionalTag])
      this.nTaxes--;

      this.updateTotalAdditionalAmount()
    },
    updateAdditionalTaxable(index){   
      let percentage = this.additionalVatPercentage[index]
      if(String(percentage).length == 1)
        percentage = "0" + String(percentage)

      if(!!this.additionalTaxable[index] && this.additionalVatPercentage[index] != undefined){
        this.additionalAmount[index] = (Number(this.additionalTaxable[index]) * Number('1.' + String(percentage))).toFixed(2)
        this.additionalVatAmount[index] = Number(Number(this.additionalAmount[index]) - this.additionalTaxable[index]).toFixed(2)
        invoiceForm.updateFields(['additionalVatPercentage','additionalTaxable','additionalVatAmount','additionalAmount', 'additionalTag'], [this.additionalVatPercentage, this.additionalTaxable, this.additionalVatAmount, this.additionalAmount, this.additionalTag])
      }
    },
    updateTotalAdditionalAmount() {
      if (this.additionalAmount.length > 0)
        this.totAdditionalAmount = this.additionalAmount.reduce((a, b) => {
          if (a === undefined) a = 0
          if (b === undefined) b = 0
          return Number(a) + Number(b)
        }, 0)
    },
  },
  computed:{
    getLinkedInvoiceDescription() {
      if (!!this.linkedInvoice) {
        return (
          this.linkedInvoice.internalCode + (this.linkedInvoice.documentNumber ? " - " + this.linkedInvoice.documentNumber : "")
        );
      } else {
        return "";
      }
    },
    overTotalAmount() {
      return !!this.linkedInvoice && (this.totAdditionalAmount > Number(this.linkedInvoice.amount))
    }
  },
  watch: {
    $attrs(newVal) {
      if (!!newVal.invoice) {
        if (
          !!newVal.invoice.invoiceOrder &&
          !!newVal.invoice.invoiceOrder.order
        )
          this.orderSelected = newVal.invoice.invoiceOrder.order[0];
        else if (!!newVal.invoice.bills && !!newVal.invoice.bills[0])
          this.billSelected = newVal.invoice.bills[0];
        if (
          !!newVal.invoice.invoiceSupplier &&
          !!newVal.invoice.invoiceSupplier.supplier
        )
          this.handleInvoiceHeadedToSelected("supplier");
        else if (
          !!newVal.invoice.invoiceCustomer &&
          !!newVal.invoice.invoiceCustomer.customer
        )
          this.handleInvoiceHeadedToSelected("customer");
        if (!["warehouse", "cashDesk"].includes(newVal.invoice.type))
          this.handleInvoiceTypeSelected(newVal.invoice.type);
        else {
          this.code = newVal.invoice.code;
        }
      }
    },
    formValid(newVal) {
      this.$emit("update:valid", newVal);
      if (this.bus) {
        this.bus.$emit("update:valid", newVal);
      }
      invoiceForm.setValid(newVal);
    },
  },
};
</script>
<style scoped>
.border-default {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #dcd8d8;
  transition: all 0.2s;
  transform: scale(1);
}
</style>